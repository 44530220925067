import { render, staticRenderFns } from "./PrincipalLayout.vue?vue&type=template&id=23a11c2a&scoped=true&"
import script from "./PrincipalLayout.vue?vue&type=script&lang=js&"
export * from "./PrincipalLayout.vue?vue&type=script&lang=js&"
import style0 from "./PrincipalLayout.vue?vue&type=style&index=0&id=23a11c2a&prod&lang=less&"
import style1 from "./PrincipalLayout.vue?vue&type=style&index=1&id=23a11c2a&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23a11c2a",
  null
  
)

export default component.exports