<template>
  <div class="principal-layout">
    <div class="pl-bgs">
      <div class="sphere sphere-1"></div>
      <div class="sphere sphere-2"></div>
    </div>
    <header class="principal-layout__header">
      <div class="container">
        <div class="principal-layout__header-row">
          <div class="principal-layout__logo">
            <img
              src="@/assets/images/logo_horizontal.svg"
              alt="Национальная технологическая олимпиада" />
          </div>

          <div class="principal-layout__box">
            <BviButton />
            <div class="principal-layout__user">
              <div
                v-if="user"
                class="u-block">
                <div class="u-block__data">
                  <div class="u-block__email">{{ user.email }}</div>
                  <a :href="`${$store.state.clientApiPrefix}/logout/`">Выйти</a>
                </div>
                <BaseAvatar
                  class="u-block__image"
                  :image="user.avatar"
                  :gender="user.sex"></BaseAvatar>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <main
      id="main-content"
      class="principal-layout__body">
      <router-view />
      <ModalsContainer class="principal-layout__modals" />
    </main>
    <AppFooter
      class="principal-layout__footer"
      theme="transparent">
      <template #links>
        <a
          href="https://talent.kruzhok.org/policy"
          target="_blank"
          rel="noopener noreferrer"
          >Политика обработки персональных данных</a
        >
        <a
          href="https://talent.kruzhok.org/terms_of_use"
          target="_blank"
          rel="noopener noreferrer"
          >Пользовательское соглашение</a
        >
      </template>
    </AppFooter>
  </div>
</template>

<script>
import AppFooter from "./AppFooter";
import BviButton from "@/components/BviButton.vue";
export default {
  name: "PrincipalLayout",
  components: {
    BviButton,
    AppFooter,
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
  },
  created() {
    document.documentElement.classList.add("principal-layout-root");
  },
  beforeDestroy() {
    document.documentElement.classList.remove("principal-layout-root");
  },
};
</script>
<style lang="less">
.principal-layout-root {
  background: #000a2e;
  color: @lp-base-color;

  body {
    // background-image: url("../assets/images/principal/bg.jpg");
    background-size: 100% auto;
    background-color: #000a2e;
    background-repeat: no-repeat;
    color: @lp-base-color;
    overflow-x: hidden;
  }
}
</style>
<style lang="less" scoped>
.principal-layout {
  display: flex;
  min-height: 100vh;
  flex-direction: column;

  &__box {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .pl-bgs {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    @supports (filter: blur(20vh)) {
      display: block;
    }

    .sphere {
      position: absolute;
      width: 40vw;
      height: 40vw;
      top: 10vh;
      background: #041b54;
      filter: blur(20vh);
      right: -20vw;
    }

    .sphere-1 {
      top: 10vh;
      right: -20vw;
    }
    .sphere-2 {
      top: -30vh;
      left: -30vw;
    }
  }

  &__header {
    flex-grow: 0;
    margin-bottom: 20px;
    padding: 32px 0;
    position: relative;
    z-index: 1;

    &-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__logo {
    width: 130px;

    img {
      width: 100%;
    }
  }

  &__footer {
    flex-grow: 0;
    z-index: 1;
  }

  &__body {
    flex-grow: 1;
    position: relative;
    z-index: 2;
  }
}

.u-block {
  display: inline-flex;
  align-items: center;

  &__data {
    flex-grow: 1;
    text-align: right;
    color: @chambray;
    .fz(14);
  }

  &__email {
    margin-bottom: 0.2em;
  }

  &__image {
    flex-grow: 0;
    flex-shrink: 0;
    width: 40px;
    overflow: hidden;
    margin-left: 20px;
    background-color: @lp-outline-color;

    & ::v-deep .base-avatar__placeholder {
      background-color: @lp-outline-color !important;
    }
  }

  a {
    color: @anakiwa;
    transition: color 0.3s;

    &:hover {
      color: lighten(@anakiwa, 10%);
      text-decoration: none;
    }
  }
  @media screen and (max-width: @screen-xs) {
    &__email {
      display: none;
    }
  }
}
</style>
